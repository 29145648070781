import React from 'react';
import './Partners.scss'

const Partners = () => {
    return (
      <div className="partners">
        <div className="partners-container">
          <div className="partners-container__title">Partenaires</div>
          <div className="partners-container__logos">
            <div className="icon icon-186-80 partner-assurance-retraite-icon"/>
            <div className="icon icon-186-80 partner-groupe-carrefour-icon"/>
            <div className="icon icon-186-80 partner-frichti-icon"/>
            <div className="icon icon-186-80 partner-croce-rossa-italiana-icon"/>
            <div className="icon icon-186-80 partner-amazon-icon"/>
            <div className="icon icon-186-80 partner-axance-icon"/>
            <div className="icon icon-186-80 partner-ulule-icon"/>
            <div className="icon icon-186-80 partner-kiss-kiss-bank-bank-icon"/>
            <div className="icon icon-186-80 partner-fspf-icon"/>
            <div className="icon icon-186-80 partner-ais-icon"/>
            <div className="icon icon-186-80 partner-kiabi-icon"/>
            <div className="icon icon-186-80 partner-aao-icon"/>
            <div className="icon icon-186-80 partner-fondation-de-france-icon"/>
            <a href="https://solidaires-handicaps.fr/" target="_blank" rel="noopener noreferrer"
              title="Solidaires Handicap">
              <div className="icon icon-186-80 partner-solidaires-handicap-icon"/>
            </a>
          </div>
        </div>
      </div>
    );
};

export default Partners;
