import React from 'react';
import './Button.scss'
import PropTypes from 'prop-types';

const Button = ({color, value, link}) => {

  const _getClassName = () => {
    switch (color) {
      case 'LIPSTICK':
        return 'button button-lipstick';
      case 'MARINE':
        return 'button button-marine';
      default:
        return 'button button-default';
    }
  };

  return (
    <a title={value} href={link} target="_blank" rel="noopener noreferrer"
       className={_getClassName()}
    >
      {value}
    </a>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['LIPSTICK', 'MARINE']).isRequired,
  value: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default Button;
