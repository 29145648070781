import React from 'react';
import './Header.scss';
import Button from '../button/Button';
import PropTypes from 'prop-types';
import scrollToBlock from '../../assets/common/common'

const Header = ({scrollToCroixRougeEcoute, scrollToLivraisons}) => {

  const text = [
    {
      id: 1,
      color: 'MARINE',
      value: 'Devenez bénévole',
      link: 'https://www.croix-rouge.fr/Je-m-engage/Benevolat/Offres-de-benevolat-pres-de-chez-vous'
    },
    {
      id: 2,
      color: 'LIPSTICK',
      value: 'Faire un don',
      link: 'https://soutenir.croix-rouge.fr/'
    }
  ];

  const backToTop = () => {
    scrollToBlock(0, 0);
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="icon icon-283-48 logo-small-icon" onClick={backToTop}>
        </div>
        <div className="header-container__shortcuts">
          <div className="header-container__shortcuts-item" onClick={scrollToCroixRougeEcoute}>Croix-Rouge Ecoute</div>
          <div className="header-container__shortcuts-item" onClick={scrollToLivraisons}>Les Livreurs Solidaires</div>
        </div>
        <div className="header-container__buttons">
          {text.map((elem) => <Button key={elem.id} color={elem.color} value={elem.value} link={elem.link}/>)}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  scrollToCroixRougeEcoute: PropTypes.func.isRequired,
  scrollToLivraisons: PropTypes.func.isRequired
};

export default Header;
