import React from 'react';
import './Livraisons.scss';
import manWomanImg from '../../assets/images/livraisons/man-woman.png'
import singleManImg from '../../assets/images/livraisons/single-man.png'
import singleWomanImg from '../../assets/images/livraisons/single-woman.png'

const Livraisons = ({refProp}) => {
    return (
      <div className="livraisons" ref={refProp}>
        <div className="livraisons-container">
          <div className="livraisons__left">
            <div className="livraisons__left-white-block"></div>
            <div className="livraisons__left-text-block">
              <h1 className="livraisons__left-title">
                Les livreurs solidaires
              </h1>
              <p className="livraisons__left-main-text">Ce dispositif vous permet de vous faire livrer à domicile des produits de première nécessité : denrées alimentaires, produits d’hygiène et médicaments sur ordonnance. Ces produits restent à votre charge, ils vous seront livrés par les bénévoles de la Croix-Rouge française.</p>
              <p className="livraisons__left-secondary-text">En appelant lez numéro non surtaxé et disponible 7J/7 de 8h à 20h, vous pouvez  bénéficier :</p>
              <ul className="livraisons__left-list">
                <li>de la possibilité de commander des produits de première nécessité livrés par des volontaires de la Croix-Rouge (le prix coûtant des produits reste à la charge des appelants, mais la Croix-Rouge prend en charge tout le reste) ;</li>
                <li>d’aide pour aller chercher vos médicaments dans vos pharmacies.</li>
              </ul>
            </div>
          </div>
          <div className="livraisons__right">
              <h1 className="livraisons__right-title">Les livreurs solidaires</h1>
              <img className="livraisons-man-woman-img" src={manWomanImg} alt="man-woman"></img>
              <img className="livraisons-single-man-img" src={singleManImg} alt="single-man"></img>
              <img className="livraisons-single-woman-img" src={singleWomanImg} alt="single-woman"></img>
          </div>
        </div>
      </div>
    );
};

export default Livraisons;
