import React from 'react';
import './Splashscreen.scss';
import SplashscreenCta from '../splashscreen-cta/SplashscreenCta';

const Splashscreen = () => {



  return (
    <div className="splashscreen">
      <div className="splashscreen-container">

        <div className="splashscreen__left">
          <div className="splashscreen__left-cta">
            <SplashscreenCta/>
          </div>
        </div>

        <div className="splashscreen__right">
          <div className="splashscreen__right-cta">
            <SplashscreenCta/>
          </div>
          <p className="splashscreen__right-text">Vous vivez seul(e) ? Votre état ne vous permet pas de faire vos courses ou d’aller chercher vos médicaments à la pharmacie ? Vous vous sentez isolé(e), la situation actuelle vous inquiète et vous souhaitez en parler ? </p>
          <h1 className="splashscreen__right-title">La Croix-Rouge française est là pour vous ! </h1>
          <p className="splashscreen__right-secondary-text">Pour répondre à la crise Covid-19 et aider les plus fragiles, notre association a mis en place Croix-Rouge Chez Vous, un dispositif exceptionnel qui s’adresse aux personnes confinées qui n’ont personne autour d’elles pour les aider ou avec qui parler.</p>
        </div>

      </div>
    </div>
  );
}

export default Splashscreen;
