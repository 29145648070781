import React from 'react';
import './CroixRougeChezVous.scss';
import ChezVousMobileImg from '../../assets/images/chezVous/chez-vous-mobile.png';
import ChezVousDesktopImg from '../../assets/images/chezVous/chez-vous-desktop.png';
import ExpansionPanelAccordion from '../expansion-panel-accordion/ExpansionPanelAccordion';

const CroixRougeChezVous = () => {
  const chezVousExpansion = [
    {
      id: 1,
      title: 'Qui peut appeler ?',
      content: 'Toute personne confinée et isolée socialement ayant besoin de parler, d’être écoutée et rassurée. Toute personne vulnérable ne pouvant se déplacer pour aller faire ses courses, chercher ses médicaments peut solliciter l’aide de « Croix-Rouge chez vous ». Vous connaissez des personnes en situation d’isolement ? Faites connaître ce service à vos proches, dans votre immeuble, chez vos commerçants, dans vos mairies...',
    },
    {
      id: 2,
      title: 'Comment ça marche ?',
      content: 'Vous appelez le 09 70 28 30 00 et vous vous laissez guider. Selon votre situation et vos besoins, vous serez orienté vers les services d’écoute adaptés - soutien psychologique, information sur la situation -, ou vers nos bénévoles qui vous proposeront de commander les produits disponibles* (produits alimentaires, produits d’hygiène, produits pour bébés) ou de se rendre à la pharmacie pour récupérer vos médicaments* sur ordonnance.\n\n *Attention, le paiement des produits reste à la charge du demandeur. '
    },
    {
      id: 3,
      title: 'Y-a il un risque pour ma sécurité ?',
      content: 'La Croix-Rouge, en tiers de confiance, sera le garant de la sécurité sanitaire des personnes isolées chez qui elle se rendra, mais aussi de ses livreurs bénévoles. Bien entendu, les données (personnelles et bancaires) qui seraient partagées lors de la transaction seront aussi protégées.'
    }
  ];

  return (
    <div className="chez-vous">
      <div className="chez-vous-container">
        <div className="chez-vous__left">
          <div className="chez-vous__left-description">
            <h1 className="chez-vous__left-description-title">Croix-Rouge chez vous</h1>
            <p className="chez-vous__left-description-text">
              En composant ce numéro non surtaxé, vous serez mis en relation avec une équipe de volontaires
              Croix-Rouge.
              À votre écoute, ils prendront le temps de vous parler, de vous informer et de vous orienter.
            </p>
          </div>
          <div className="chez-vous__left-expansion">
            <ExpansionPanelAccordion displayedElement={chezVousExpansion}/>
          </div>
        </div>
        <div className="chez-vous__right">
          <div className="chez-vous__right-description">
            <h1 className="chez-vous__right-description-title">Croix-Rouge chez vous</h1>
            <p className="chez-vous__right-description-text">
              En composant ce numéro non surtaxé, vous serez mis en relation avec une équipe de volontaires
              Croix-Rouge.
              À votre écoute, ils prendront le temps de vous parler, de vous informer et de vous orienter.
            </p>
          </div>
          <div className="chez-vous-img-mobile__container">
            <img className="chez-vous-img-mobile" src={ChezVousMobileImg} alt="Croix Rouge chez Vous"></img>
          </div>
          <img className="chez-vous-img-desktop" src={ChezVousDesktopImg} alt="Croix Rouge chez Vous"></img>
        </div>
      </div>
    </div>
  );
};

export default CroixRougeChezVous;
