import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner">
      <a className="banner-container" rel="noopener noreferrer"
          title="En savoir plus sur les activités de la Croix-Rouge"
          href="https://www.croix-rouge.fr/Actualite/Coronavirus-COVID-19/Covid-19-la-Croix-Rouge-francaise-a-besoin-de-vous-pour-aider-les-plus-fragiles-2357"
          target="_blank">
        <p className="banner-container__text">
          En savoir plus sur&nbsp;
          <span className="banner-container__text--bold">
          les activités de la Croix-Rouge
          française face à la crise du Covid-19
          </span>
        </p>
        <div className="icon icon-15-20 right-arrow-white-icon"/>
      </a>
    </div>
  );
};

export default Banner;
