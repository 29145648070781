import React from 'react';
import './FooterSecondSection.scss'
import scrollToBlock from '../../assets/common/common';

const FooterSecondSection = () => {
  const backToTop = () => {
    scrollToBlock(0, 0);
  };
  return (
    <div className="footer-second-section">
      <div className="footer-second-section-container">
        <div>© 2020 Copyrights CRF | Illustrations : Pau Gasol Valls.</div>
        <div className="footer-second-section-container__action" onClick={backToTop}>
          <div className="icon icon-24 back-to-top-icon"></div>
          <div>Retour en haut</div>
        </div>
      </div>
    </div>
  );
};

export default FooterSecondSection;
