import React from 'react';
import './CroixRougeEcoute.scss'
import Button from '../button/Button';
import mobileCroixRougeEcouteImg from '../../assets/images/ecouteCroixRouge/ecoute-mobile.png'
import desktopCroixRougeEcouteImg from '../../assets/images/ecouteCroixRouge/ecoute-desktop.png'

const CroixRougeEcoute = ({refProp}) => {
  return (
    <div className="ecoute" ref={refProp}>
      <div className="ecoute-container">
        <div className="ecoute__left">
          <div className="ecoute__left-pink-block"></div>
          <img className="ecoute-mobile-img" src={mobileCroixRougeEcouteImg} alt="ecoute croix rouge"></img>
          <img className="ecoute-desktop-img" src={desktopCroixRougeEcouteImg} alt="ecoute croix rouge"></img>
        </div>
        <div className="ecoute__right">
          <h1 className="ecoute__right-title">Croix-Rouge Écoute</h1>
          <p className="ecoute__right-main-text">Si vous ressentez le besoin de parler, de partager vos interrogations
            ou vos peurs, notre équipe dédiée est là pour vous apporter une écoute chaleureuse et bienveillante,
            vous rassurer, vous donner des informations fiables et, si nécessaire, vous orienter vers d’autres
            services. Des professionnels sont également disponibles pour vous apporter un soutien psychologique. </p>
          <p className="ecoute__right-secondary-text">
            Dans le cadre des mesures de confinement Covid-19, la Croix-Rouge française met à disposition cette ligne
            d’appel pour écouter, informer et répondre aux questions liées à la situation exceptionnelle dans laquelle
            se trouvent les Français aujourd’hui.
            <br/><br/>
            Nos bénévoles et nos professionnels offrent à chacun un temps d’écoute, un moment de pause, un espace de
            parole, dans un cadre neutre et bienveillant. Ces appels peuvent permettre d’apaiser des tensions, des
            craintes, des angoisses, des moments d’agressivité, ou de mettre un peu d’ordre dans ses pensées.
          </p>
          <div className="ecoute__right-cta">
            <Button color={'LIPSTICK'} value={'Accéder à Croix-Rouge écoute'}
                    link={'https://www.croix-rouge.fr/Nos-actions/Action-sociale/Ecoute-acces-aux-droits/Croix-Rouge-Ecoute-service-de-soutien-psychologique-par-telephone'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CroixRougeEcoute;
