import React, { useState } from 'react';
import './ExpansionPanelAccordion.scss'
import PropTypes from 'prop-types';
import ExpansionPanel from './expansion-panel/ExpansionPanel';


const ExpansionPanelAccordion = ({displayedElement}) => {

  const [indexOpened, setIndexOpened] = useState(0);
  const open = (index) => {
    setIndexOpened(index);
  };
  const close = () => {
    setIndexOpened(null);
  };

  return (
    <div>
      {displayedElement.map((item, index) => <div key={item.id} className="expansion-item"><ExpansionPanel title={item.title} content={item.content} isClose={index !== indexOpened} open={() => open(index)} close={close} /></div>)}
    </div>
  )

};

ExpansionPanelAccordion.propTypes = {
  displayedElement: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ExpansionPanelAccordion;
