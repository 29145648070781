import React from 'react';
import './SplashscreenCta.scss';
import Button from '../button/Button';

const SplashscreenCta = () => {
  const text = [
    {
      id: 1,
      color: 'MARINE',
      value: 'Devenez bénévole',
      link: 'https://www.croix-rouge.fr/Je-m-engage/Benevolat/Offres-de-benevolat-pres-de-chez-vous'
    },
    {
      id: 2,
      color: 'LIPSTICK',
      value: 'Faire un don',
      link: 'https://soutenir.croix-rouge.fr/'
    }
  ];

  return (
    <div className="splashscreen-cta">
      <div className="splashscreen-cta__logo">
        <div className="icon logo-splashscreen-icon"></div>
      </div>
      <div className="splashscreen-cta__desc">
        <div className="splashscreen-cta__desc-calendar">
          <div className="icon calendar-rounded-icon"></div>
          <p>7j/7</p>
        </div>
        <div className="splashscreen-cta__desc-clock">
          <div className="icon clock-rounded-icon"></div>
          <p>de 8h à 20h</p>
        </div>
      </div>
      <div className="splashscreen-cta__buttons">
        {text.map((elem) => <Button key={elem.id} color={elem.color} value={elem.value} link={elem.link}/>)}
      </div>
    </div>
  );
}

export default SplashscreenCta;
