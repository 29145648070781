import React from 'react';
import './LivraisonsAccordion.scss';
import LivraisonMobileImg from '../../assets/images/livraisons/livraisons-mobile.png';
import LivraisonDesktopImg from '../../assets/images/livraisons/livraisons-desktop.png';
import ExpansionPanelAccordion from '../expansion-panel-accordion/ExpansionPanelAccordion';

const LivraisonsAccordion = () => {

  const livraisonsExpansion = [
    {
      id: 1,
      title: 'Quel type de denrées peuvent-être livrées ?',
      content: 'En ce qui concerne les denrées alimentaires, chaque personne pourra choisir quelques lots de produits (laitages, fruits et légumes, féculents…) pour construire un panier personnalisé. Des kits d’hygiène pour hommes et femmes seront aussi disponibles. En ce qui concerne les médicaments, nos bénévoles pourront récupérer à la pharmacie les médicaments sur ordonnance de la personne appelante.',
    },
    {
      id: 2,
      title: 'Concrètement, l’opération est-elle payante ?',
      content: 'Le prix des produits reste à la charge des appelants, mais bien évidemment la Croix-Rouge prend en charge le processus de commande, de confection et de livraison sans coût pour la personne.'
    },
    {
      id: 3,
      title: 'Comment assurer la sécurité des bénévoles Croix-Rouge et des personnes isolées ?',
      content: 'Nous appliquons des protocoles sanitaires stricts qui garantissent la sécurité des livreurs solidaires : ils portent des équipement de protection individuelle (masques, gants), appliquent strictement les gestes barrières et conservent bien évidemment une distance importante avec les personnes confinées.\n \nComme tous les acteurs du médico-social et de solidarité, nous souffrons aujourd’hui de la pénurie de masques et avons sollicité les pouvoirs publics et la Fédération Internationale de la Croix-Rouge et du Croissant-Rouge (FICR) pour être approvisionné.'
    }
  ];

  return (
    <div className="livraisons-accordion">
      <div className="livraisons-accordion-container">
        <div className="livraisons-accordion__left">
          <div className="livraisons-accordion__left-expansion">
            <ExpansionPanelAccordion displayedElement={livraisonsExpansion}/>
          </div>
        </div>
        <div className="livraisons-accordion__right">
          <div className="livraisons-accordion__right-white-block"></div>
          <img className="livraison-accordion-img-mobile" src={LivraisonMobileImg} alt="livraison"></img>
          <img className="livraison-accordion-img-desktop" src={LivraisonDesktopImg} alt="livraison"></img>
        </div>
      </div>
    </div>
  );
};

export default LivraisonsAccordion;
