import React from 'react';
import './ExpansionPanel.scss'
import PropTypes from 'prop-types';

const ExpansionPanel = ({title, content, isClose, open, close}) => {
  
  const _getClassNameTitle = () => {
    switch (!isClose) {
      case true:
        return 'expansion-panel__header-title expansion-panel__header-title--lipstick';
      case false:
        return 'expansion-panel__header-title expansion-panel__header-title--marine';
      default:
        return 'expansion-panel__header-title expansion-panel__header-title--marine';
    }
  };

  const _getClassNameContent = () => {
    switch (!isClose) {
      case true:
        return 'expansion-panel__content expansion-panel__content--opened';
      case false:
        return 'expansion-panel__content expansion-panel__content--closed';
      default:
        return 'expansion-panel__content expansion-panel__content--closed';
    }
  };


  return (
    <div className="expansion-panel">
      <div className="expansion-panel__header" onClick={isClose ? open : close}>
        <div className={_getClassNameTitle()}>{title}</div>
        {!isClose
          ? <div className="expansion-icon icon icon-14 cross-close-red-icon"/>
          : <div className="expansion-icon icon icon-14 cross-open-blue-icon"/>
        }
      </div>
      <div className={_getClassNameContent()}>{content}</div>
    </div>
  );
};

ExpansionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isClose: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};


export default ExpansionPanel;
