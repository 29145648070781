import React  from 'react';
import './App.scss';
import Header from './components/header/Header';
import Splashscreen from './components/splashscreen/Splashscreen';
import CroixRougeChezVous from './components/croix-rouge-chez-vous/CroixRougeChezVous';
import Banner from './components/banner/Banner';
import CroixRougeEcoute from './components/croix-rouge-ecoute/CroixRougeEcoute';
import Livraisons from './components/livraisons/Livraisons';
import LivraisonsAccordion from './components/livraisons-accordion/LivraisonsAccordion';
import Partners from './components/partners/Partners';
import FooterFirstSection from './components/footerFirstSection/FooterFirstSection';
import FooterSecondSection from './components/footerSecondSection/FooterSecondSection';
import scrollToBlock from './assets/common/common';

class App extends React.Component{

  constructor(props) {
    super(props);
    this.croixRougeEcouteRef = React.createRef();
    this.livraisonsRef = React.createRef();
  }

  navigateCroixRouge = () => {
    scrollToBlock(0, this.croixRougeEcouteRef.current.offsetTop);
  };

  navigateLivraisons = () => {
    scrollToBlock(0, this.livraisonsRef.current.offsetTop);
  };

  render() {
    return (
      <div className="app-container">
        <Header scrollToCroixRougeEcoute={this.navigateCroixRouge} scrollToLivraisons={this.navigateLivraisons}/>
        <div className="app-content">
          <Splashscreen/>
          <CroixRougeChezVous/>
          <Banner/>
          <CroixRougeEcoute refProp={this.croixRougeEcouteRef}/>
          <Livraisons refProp={this.livraisonsRef}/>
          <LivraisonsAccordion/>
          <Partners/>
          <FooterFirstSection/>
          <FooterSecondSection/>
        </div>
      </div>
    );
  }
}

export default App;
