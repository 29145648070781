import React from 'react';
import './FooterFirstSection.scss'

const FooterFirstSection = () => {
  return (
    <div className="footer-first-section">
      <div className="footer-first-section-container">
        <div className="icon icon-259-86 logo-bottom-icon"></div>
        <div className="footer-first-section-container__information">
          <div className="footer-first-section-container__information-head-quarter">
            <p>
              <span
                className="footer-first-section-container__information-head-quarter--title">Siège social :</span><br/>
              98 rue Didot, 75694 Paris Cedex
            </p>
          </div>
          <div className="footer-first-section-container__information-phone">
            <div className="footer-first-section-container__information-phone--title">Tel :</div>
            <a rel="noopener noreferrer" title="Téléphone" className="footer-first-section-container__information-phone--link"
               href="tel:+33144431100">+33 (0)1 44 43 11 00</a>
          </div>
          <div className="footer-first-section-container__information-social-media">
            <a rel="noopener noreferrer" title="Croix Rouge Twitter" href="https://twitter.com/CroixRouge" target="_blank"
               className="footer-first-section-container__information-social-media--icon">
              <div className="icon icon-48 footer-twitter-icon"></div>
            </a>
            <a rel="noopener noreferrer" title="Croix Rouge Facebook" href="https://www.facebook.com/fr.CroixRouge" target="_blank"
               className="footer-first-section-container__information-social-media--icon">
              <div className="icon icon-48 footer-facebook-icon"></div>
            </a>
            <a rel="noopener noreferrer" title="Croix Rouge LinkedIn" href="https://www.linkedin.com/company/croix-rouge-fran-aise/" target="_blank"
               className="footer-first-section-container__information-social-media--icon">
              <div className="icon icon-48 footer-linkedin-icon"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterFirstSection;
